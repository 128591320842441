import React from "react";
import { Modal } from "react-bootstrap";
import i18n from "i18next";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { connect } from "react-redux";
import {
  LocalApiBaseUrl,
  regexForNumbersOnly,
  regexForFields,
  ImageFormats,
  regexForToolURL,
  commonToolRegex,
} from "../Shared/Constant";
import { validateIsAllowedDomainFromURL, fetchAllowedDomains,isValidText,InvalidDescription } from '../Shared/commonFunctions';
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";

const assetTypeData = [
    {
        Id:1,
        Name:"Accenture"
    },
    {
        Id:2,
        Name:"Client"
    },
]

class AddNewAsset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
      mode: "add",
      selectedfile: null,
      asset: {
        assetName: "",
        assetType: "",
        assetTypeId: 0,
        id: 0,
        assetURL: "",
        assetOpriaURL: "",
        assetMoreInfo: "",
        assetDefaultUserId: "",
        assetDesc: "",
        assetSequence: 1,
        assetBusinessBenefit: "",
        assetFile: "",
        icon: null,
        fileName: "",
        orgEntityID: 0,
        isDisable: false,
        isFixDisable: false,
        IconBase64: "",
        toolType: "",
        IsAAA:false,
        openInNewWindow: false,
      },
      errors: {},
      toolCategoryList: [],
      toolLeversList: [],
      maxSequences: this.props.maxSequences,
      showMoreInfo: false,
      isAPILoading: false,
      allowedDomainsArray: [],
    };
  }
  handleValidation = (event) => {
    const { allowedDomainsArray } = this.state;

    let errors = {};
    let formIsValid = true;
    let asset = this.state.asset;
    if (asset.assetName === "") {
      errors["assetName"] = i18n.t("addWoErrorM1");
      formIsValid = false;
    } else {

      if (regexForFields.test(asset.assetName)) {
        errors["assetName"] = "";
        if(!isValidText(asset.assetName)){
          errors["assetName"] = InvalidDescription();
      formIsValid = false;
    }
      }  
      else {
        errors["assetName"] = i18n.t("AssetN_special_char");
        formIsValid = false;
      }
    }
    if (asset.assetType === "") {
      errors["assetType"] = i18n.t("Type_required");
      formIsValid = false;
    }
    if (asset.assetDesc === "") {
      errors["assetDesc"] = i18n.t("ToolDescription_Required");
      formIsValid = false;
    } else {
      if (regexForFields.test(asset.assetDesc)) {
        errors["assetDesc"] = "";
        if(!isValidText(asset.assetDesc)){
          errors["assetDesc"] = InvalidDescription();
      formIsValid = false;
    }
      } 
      else {
        errors["assetDesc"] = i18n.t(" AssetDes_special");
        formIsValid = false;
      }
    }
    if (asset.assetURL === "") {
      errors["assetURL"] = "";
    } else {
      if (!commonToolRegex.test(asset.assetURL.trim().toLowerCase())) {
        if (regexForToolURL.test(asset.assetURL.trim().toLowerCase())) {
          const isAllowed = validateIsAllowedDomainFromURL(asset.assetURL.trim(), allowedDomainsArray);
          if (!isAllowed) {
            errors['assetURL'] = i18n.t('subdomain_or_domain_not_whitelisted');
            formIsValid = false;
          } else {
            errors["assetURL"] = "";
          }
        } else {
          errors["assetURL"] = i18n.t("Please_valid_url");
          formIsValid = false;
        }
      } else {
        errors["assetURL"] = i18n.t("commonToolRegex_validatemessage");
        formIsValid = false;
      }
    }
    if (asset.assetOpriaURL === "") {
      errors["assetOpriaURL"] ="";
    } else {
      if (!commonToolRegex.test(asset.assetOpriaURL.trim().toLowerCase())) {
        if (regexForToolURL.test(asset.assetOpriaURL.trim().toLowerCase())) {
          const isAllowed = validateIsAllowedDomainFromURL(asset.assetOpriaURL.trim(), allowedDomainsArray);
          if (!isAllowed) {
            errors['assetOpriaURL'] = i18n.t('subdomain_or_domain_not_whitelisted');
            formIsValid = false;
          } else {
            errors["assetOpriaURL"] = "";
          }
        } else {
          errors["assetOpriaURL"] = i18n.t("Please_valid_url");
          formIsValid = false;
        }
      } else {
        errors["assetOpriaURL"] = i18n.t("commonToolRegex_validatemessage");
        formIsValid = false;
      }
    }
    if (asset.assetMoreInfo === "") {
      errors["assetMoreInfo"] = "";
    } else {
      if (!commonToolRegex.test(asset.assetMoreInfo.trim().toLowerCase())) {
        if (regexForToolURL.test(asset.assetMoreInfo.trim().toLowerCase())) {
          errors["assetMoreInfo"] = "";
        } else {
          errors["assetMoreInfo"] = i18n.t("Please_valid_url");
          formIsValid = false;
        }
      } else {
        errors["assetMoreInfo"] = i18n.t("commonToolRegex_validatemessage");
        formIsValid = false;
      }
    }
    if (asset.assetDefaultUserId === "") {
      errors["assetDefaultUserId"] = "";
    } else {
      if (regexForFields.test(asset.assetDefaultUserId)) {
        errors["assetDefaultUserId"] = "";
      } else {
        errors["assetDefaultUserId"] = i18n.t("AssetDefU_special");
        formIsValid = false;
      }
    }
    if (asset.assetSequence === null || asset.assetSequence === "") {
      errors["assetSequence"] = i18n.t("addWoErrorM5");
      formIsValid = false;
    } else {
      if (asset.assetSequence < 1 || asset.assetSequence > 999999) {
        errors["assetSequence"] = i18n.t("addWoErrorM6");
        formIsValid = false;
      } else if (regexForNumbersOnly.test(asset.assetSequence)) {
        errors["assetSequence"] = "";
      }
    }

    if (asset.assetBusinessBenefit === "") {
      errors["assetBusinessBenefit"] = "";
    } 
    else {
      if (regexForFields.test(asset.assetBusinessBenefit)) {
        errors["assetBusinessBenefit"] = "";

        if(!isValidText(asset.assetBusinessBenefit)){
          errors["assetBusinessBenefit"] = InvalidDescription();
      formIsValid = false;
    }
      } 
      else {
        errors["assetBusinessBenefit"] = i18n.t("AssetBB_special");
        formIsValid = false;
      }
    }
    if (
      asset.fileName &&
      ImageFormats.includes(
        asset.fileName ? asset.fileName.split(".").pop() : ""
      )
    ) {
      if (parseFloat(this.state.selectedfile.size / 1024).toFixed(2) <= 10) {
        errors["icon"] = "";
      } else {
        errors["icon"] = i18n.t("Icon_less_ten");
        formIsValid = false;
      }
    } else {
      if (!asset.fileName) {
        errors["icon"] = "";
      } else {
        errors["icon"] = i18n.t("Icon_format_Error");
        formIsValid = false;
      }
    }
    if (String(this.props.details.tabName) === "Work Orchestration") {
      if (asset.toolType === "" || String(asset.toolType) === "0") {
        errors["toolType"] = "Tool Type is required";
        formIsValid = false;
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  onAssetOwnedChange = (event) => {
    let asset = this.state.asset;
    let id = event.target.options[event.target.selectedIndex].value;
    asset.assetType = assetTypeData.filter((a) => a.Id === parseInt(id)).length>0 ? assetTypeData.filter((a) => a.Id === parseInt(id))[0].Name:""
    asset.assetTypeId = event.target.options[event.target.selectedIndex].value;
    if (String(asset.assetType) === "Accenture") {
      this.setState({ showMoreInfo: true });
    } else {
      this.setState({ showMoreInfo: false });
    }
    this.setState({ asset: asset });
  };
  handleOnChange = (event) => {
    let asset = this.state.asset;
    let errors = this.state.errors;
    if (!event.target.value) {
      asset[event.target.name] = event.target.value;
      errors[event.target.name] = "";
      this.setState({
        asset: asset,
        errors: errors,
      });
    } else {
      if (
        event.target.name === "assetURL"  ||event.target.name === "assetOpriaURL" ||
        event.target.name === "assetMoreInfo"
      ) {
        if (regexForToolURL.test(event.target.value.trim().toLowerCase())) {
          asset[event.target.name] = event.target.value;
          errors[event.target.name] = "";
        } else {
          asset[event.target.name] = event.target.value;
        }
      } else if (event.target.name === "assetSequence") {
        if (regexForNumbersOnly.test(event.target.value)) {
          asset[event.target.name] = event.target.value;
          errors[event.target.name] = "";
        }
      }else if(event.target.name === "IsAAA"){
        asset[event.target.name] = event.target.checked;
      }
      else if(event.target.name === "openInNewWindow"){
        asset[event.target.name] = event.target.checked;
      }
       else {
        if (regexForFields.test(event.target.value)) {
          asset[event.target.name] = event.target.value;
          errors[event.target.name] = "";
        }
      }

      this.setState({
        asset: asset,
        errors: errors,
      });
    }
  };
  handleSubmit = () => {
    let formValid = this.handleValidation();

    let socategoryDetails = this.props.pulsePageData.filter(
      (each) => each.TileName_En === this.props.details.tabName_En
    );
    let asset = this.state.asset;
    const data = new FormData();
    if (asset.fileName) {
      data.append("icon", this.state.selectedfile, asset.fileName);
    } else {
      if (asset.icon) {
        data.append("icon", []);
        data.append("IconBase64", asset.icon);
      } else {
        data.append("icon", []);
        data.append("IconBase64", "");
      }
    }

    if (formValid) {
      data.append("ApplicationMappingID", asset.id);
      data.append("OfferingID", this.props.offeringId);
      data.append("ApplicationName", asset.assetName.trim());
      data.append("ApplicationCategory", asset.assetType.trim());
      data.append("ApplicationCategoryID", parseInt(asset.assetTypeId));
      data.append("SOCategoryID", parseInt(socategoryDetails[0].TileID));
      data.append(
        "URL",
        asset.assetURL ? asset.assetURL.trim() : asset.assetURL
      );
       data.append(
        "OpriaURL",
        asset.assetOpriaURL ? asset.assetOpriaURL.trim() : asset.assetOpriaURL
      );
      data.append(
        "MoreInfoURL",
        asset.assetMoreInfo ? asset.assetMoreInfo.trim() : asset.assetMoreInfo
      );
      data.append("ApplicationDescription", asset.assetDesc.trim());
      data.append("IsActive", true);
      data.append("IsAAA",asset.IsAAA)
      data.append(
        "OrgEntityID",
        asset.id > 0
          ? asset.orgEntityID
            ? asset.orgEntityID
            : 0
          : this.props.clientData.id
      );
      data.append(
        "SequenceNumber",
        parseInt(asset.assetSequence) > 0 ? parseInt(asset.assetSequence) : 1
      );
      data.append(
        "businessBenefit",
        asset.assetBusinessBenefit ? asset.assetBusinessBenefit : ""
      );
      data.append(
        "defaultUserID",
        asset.assetDefaultUserId ? asset.assetDefaultUserId : ""
      );
      data.append("ClientID", this.props.clientData.id);
      data.append("ToolType", asset.toolType);
      data.append("OpenInNewWindow", asset.openInNewWindow);

      axiosInstance
        .post(
          `${LocalApiBaseUrl}ApplicationMapping/HandleApplicationMappingData`,
          data
        )
        .then((response) => {
          window.alert(response.data.StatusMessage);
          if (response.data.IsSuccess) {
            if (this.state.mode === "edit") {
              this.props.onAddNewAsset(response.data.ApplicationMasterModel);

              let enterSeq =
                parseInt(asset.assetSequence) > 0
                  ? parseInt(asset.assetSequence)
                  : 1;
              let maxSequences = this.state.maxSequences;
              let indexMax = this.props.maxSequences.findIndex((f) => {
                return f.SOCategory == parseInt(socategoryDetails[0].TileID);
              });
              if (
                indexMax > -1 &&
                enterSeq > maxSequences[indexMax].maxSequence
              ) {
                maxSequences[indexMax] = {
                  ...maxSequences[indexMax],
                  maxSequence: enterSeq,
                };
              }
              this.props.onSavePulseMaxSequences(maxSequences);
            }

            this.props.close(this.state.mode);
          }
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          window.alert(this.ssErrMsg + error);
        });
    }
  };

  onIconSelect = (event) => {
    let data = this.state.asset;
    let errors = this.state.errors;
    errors["icon"] = "";
    data.icon = event.target.files[0];
    let fileName = event.target.value.split("\\").pop();
    data.fileName = fileName;
    this.setState({
      asset: data,
      selectedfile: event.target.files[0],
      IconBase64: null,
      errors: errors,
    });
  };

  handleHide = () => {
    this.props.close(this.state.mode);
  };

  assetNameChange = (event) => {
    if (regexForFields.test(event.target.value)) {
      let asset = { ...this.state.asset };
      asset.assetName = event.target.value;

      this.setState({ asset: asset });
    }
  };
  getToolMasterData = () => {
    axiosInstance
      .get(
        `${LocalApiBaseUrl}ApplicationMapping/GetMasterDataForApplicationMapping`,
        {
          params: { languageID: this.props.languageData.id },
        }
      )
      .then((response) => {
        if (response.data)
          this.setState({
            toolLeversList: response.data.SOCategoryList,
            toolCategoryList: response.data.CategoryList,
          });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };
  async componentDidMount() {
    const { clientData } = this.props;

    this.setState({
      isAPILoading: true,
    });
    this.getToolMasterData();

    const reqData = {
      OrgEntityID: clientData.id
    };
    const allowedDomains = await fetchAllowedDomains(reqData);
    this.setState({
      allowedDomainsArray: allowedDomains,
      isAPILoading: false,
    });
    if (Object.keys(this.props.editdata).length !== 0) {
      const data = this.props.editdata;
      this.setState({
        mode: data.ApplicationMappingID > 0 ? "edit" : "add",
        asset: {
          assetName: data.ApplicationName,
          assetType: data.ApplicationCategory,
          assetTypeId: data.ApplicationCategoryID,
          id: data.ApplicationMappingID,
          assetURL: data.URL ? data.URL : "",
          assetOpriaURL: data.OpriaURL ? data.OpriaURL : "",

          assetMoreInfo: data.MoreInfoURL ? data.MoreInfoURL : "",
          assetDefaultUserId: data.DefaultUserID,
          assetDesc: data.ApplicationDescription,
          assetSequence: data.SequenceNumber,
          assetBusinessBenefit: data.BusinessBenefit,
          assetFile: "",
          icon: data.Icon,
          fileName: "",
          orgEntityID: data.OrgEntityID,
          isDisable: data.OrgEntityID > 0 ? false : true,
          isFixDisable: data.ApplicationMappingID > 0 ? true : false,
          IconBase64: data.Icon,
          toolType: data.Tooltype,
          IsAAA:data.IsAAA ? data.IsAAA: false,
          openInNewWindow: data.OpenInNewWindow ? data.OpenInNewWindow : false
        },
      });
      if (
        data.ApplicationMappingID > 0 &&
        String(data.ApplicationCategory) === "Accenture" &&
        String(data.MoreInfoURL) !== "" &&
        data.MoreInfoURL != null
      ) {
        this.setState({ showMoreInfo: true });
      } else {
        this.setState({ showMoreInfo: false });
      }
    } else {
      this.setState({ mode: "add" });
    }
  }
  onToolTypeChange = (event) => {
    let asset = this.state.asset;
    let errors = this.state.errors;
    errors["toolType"] = "";
    asset.toolType = event.target.value;
    this.setState({ asset: asset, errors });
  };

  render() {
    const { isAPILoading } = this.state;
    return (
      <>
        <LoadingOverlay
          className="custom-loader"
          fadeSpeed={0}
          spinner={<SynopsLoader />}
          active={isAPILoading}
        />

        <Modal
          show={this.state.show}
          onHide={this.handleHide}
          className="modal fade wo-configurator-modal"
          bsSize="medium"
          id="addPulseAssets"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">
              {this.props.details.tabName ? this.props.details.tabName : ""} -{" "}
              {this.state.mode === "edit" ? (
                <>{i18n.t("Edit")}</>
              ) : (
                <>
                  {i18n.t("Add")} {i18n.t("New")}
                </>
              )}{" "}
              {i18n.t("Asset")}
            </h4>
          </Modal.Header>
          <Modal.Body>
            <div className="addAssetModal">
              {this.state.mode !== "edit" ? (
                <>
                  <p>{i18n.t("Please_Note")}</p>
                  <p>1.{i18n.t("Tools_can_client_acc_sig")} </p>
                  <p>2.{i18n.t("One_tool_can_not")}</p>{" "}
                </>
              ) : (
                ""
              )}
              <form action="#">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label for="assetName">
                      {i18n.t("Asset_Name")} <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      disabled={this.state.asset.isFixDisable}
                      class="form-control"
                      id="assetName"
                      placeholder=""
                      maxLength="60"
                      name="assetName"
                      value={this.state.asset.assetName}
                      onChange={
                        this.handleOnChange
                      } /* onClick = {this.assetNameChange} */
                    />
                    <div className="errorMsg">
                      {this.state.errors.assetName}
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label for="assetType">
                      {i18n.t("Asset_Type")}
                      <span className="required">*</span>
                    </label>
                    <select
                      className="form-control assetTypeSelect"
                      disabled={this.state.asset.isFixDisable}
                      name="assetType"
                      value={this.state.asset.assetTypeId}
                      onChange={this.onAssetOwnedChange}
                    >
                      <option value="0">{i18n.t("Select")}</option>
                      {this.state.toolCategoryList.map((dropValue) => {
                        return (
                          <option key={dropValue.ID} value={dropValue.ID}>
                            {dropValue.Value}
                          </option>
                        );
                      })}
                    </select>
                    <div className="errorMsg">
                      {this.state.errors.assetType}
                    </div>
                  </div>
                  {String(this.props.details.tabName) ===
                    "Work Orchestration" && (
                    <div className="form-group col-md-6">
                      <label for="toolType">
                        {i18n.t("Tool_Type")}
                        <span className="required">*</span>
                      </label>
                      <select
                        className="form-control assetTypeSelect"
                        disabled={this.state.asset.isFixDisable}
                        name="toolType"
                        value={this.state.asset.toolType}
                        onChange={this.onToolTypeChange}
                      >
                        <option value="0">{i18n.t("Select_Tool_Type")}</option>
                        <option value="AA">
                          {i18n.t("Advanced_Analytics")}
                        </option>
                        <option value="AI">
                          {i18n.t("Artificial_Intelligence")}
                        </option>
                        <option value="AT">{i18n.t("Automation")}</option>
                        <option value="WF">{i18n.t("Workflow")}</option>
                      </select>
                      <div className="errorMsg">
                        {this.state.errors.toolType}
                      </div>
                    </div>
                  )}
                  <div className="form-group col-md-6">
                    <label>{i18n.t("URL_text")}</label>
                    <input
                      type="text"
                      className="form-control"
                      maxLength="5000"
                      name="assetURL"
                      id="url"
                      placeholder="URL"
                      value={this.state.asset.assetURL}
                      onChange={this.handleOnChange}
                    />
                    <div className="errorMsg">{this.state.errors.assetURL}</div>
                  </div>
                  <div className="form-group col-md-6">
                    <label>{i18n.t("Deafult_User_ID")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="assetDefaultUserId"
                      id="userid"
                      maxLength="75"
                      placeholder="Default User ID"
                      value={this.state.asset.assetDefaultUserId}
                      onChange={this.handleOnChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors.assetDefaultUserId}
                    </div>
                  </div>
                  {this.state.showMoreInfo && (
                    <div className="form-group col-md-12">
                      <label>{i18n.t("More_Information")}</label>
                      <input
                        type="text"
                        disabled={this.state.asset.isFixDisable}
                        className="form-control"
                        maxLength="5000"
                        name="assetMoreInfo"
                        id="moreInfo"
                        placeholder="Tool Information URL"
                        value={this.state.asset.assetMoreInfo}
                        onChange={this.handleOnChange}
                      />
                      <div className="errorMsg">
                        {this.state.errors.assetMoreInfo}
                      </div>
                    </div>
                  )}
                   <div className="form-group col-md-12">
                    <label for="OpriaURL">
                      {i18n.t("View Summary URL")}{" "}
                    </label>
                    <input
                      type="text"
                      //disabled={this.state.asset.isDisable}
                      className="form-control"
                      name="assetOpriaURL"
                      id="opriaurl"
                      placeholder=""
                      value={this.state.asset.assetOpriaURL}
                      maxLength="500"
                      onChange={this.handleOnChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors.assetOpriaURL}
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    <label for="description">
                      {i18n.t("Asset_Description")}{" "}
                      <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      disabled={this.state.asset.isDisable}
                      className="form-control"
                      name="assetDesc"
                      id="description"
                      placeholder=""
                      value={this.state.asset.assetDesc}
                      maxLength="70"
                      onChange={this.handleOnChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors.assetDesc}
                    </div>
                  </div>
                 
                  <>
                    {this.state.mode !== "edit" ? null : (
                      <div className="form-group col-md-12">
                        <label for="sequence">
                          {i18n.t("Sequence")}{" "}
                          <span className="required">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          min="1"
                          max="999999"
                          name="assetSequence"
                          id="sequence"
                          placeholder="Sequence Number"
                          value={this.state.asset.assetSequence}
                          onChange={this.handleOnChange}
                        />
                        <div className="errorMsg">
                          {this.state.errors.assetSequence}
                        </div>
                      </div>
                    )}
                  </>

                  <div className="form-group col-md-12">
                    <label>{i18n.t("Business_Benefit")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="assetBusinessBenefit"
                      id="businessbenifit"
                      placeholder="Business Benefit"
                      maxLength="255"
                      value={this.state.asset.assetBusinessBenefit}
                      onChange={this.handleOnChange}
                    />
                    <div className="errorMsg">
                      {this.state.errors.assetBusinessBenefit}
                    </div>
                  </div>
                  <div className="form-group custom-file-css col-md-6 mb-4">
                    <p>{i18n.t("Asset Icon")}:</p>
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="customFile"
                        accept="image/*"
                        onChange={this.onIconSelect}
                        disabled={this.state.asset.isDisable}
                        name="filename"
                      />
                      <label
                        className="custom-file-label"
                        for="customFile"
                        disabled={this.state.asset.isDisable}
                      >
                        <span>
                          {this.state.asset.fileName
                            ? this.state.asset.fileName
                            : "Choose Icon"}
                        </span>
                      </label>
                      <span className="errorMsg">{this.state.errors.icon}</span>
                    </div>
                  </div>
                  {this.state.mode === "edit" &&
                  !this.state.asset.fileName &&
                  this.state.asset.icon ? (
                    <div className="form-group custom-file-css col-md-6 mt-4">
                      <img
                        src={"data:image/png;base64," + this.state.asset.icon}
                        width="30px"
                        height="30px"
                      ></img>
                    </div>
                  ) : (
                    ""
                  )}
                   <div className='form-group col-lg-6 isAAA-check-box'>
                      <p></p>
                      <input 
                        type="checkbox" 
                        name="IsAAA"
                        checked={this.state.asset.IsAAA} 
                        value={this.state.asset.IsAAA} 
                        onChange={this.handleOnChange} 
                        />
                      
                      <span class="form-check-label">{i18n.t("IsAAA")}</span>
                  </div>
                  <div className='form-group col-lg-6 isAAA-check-box'>
                      <p></p>
                      <input 
                        type="checkbox" 
                        name="openInNewWindow"
                        checked={this.state.asset.openInNewWindow} 
                        value={this.state.asset.openInNewWindow} 
                        onChange={this.handleOnChange} 
                        />
                      
                      <span class="form-check-label">Open in new window</span>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
          {
            <Modal.Footer>
              <div>
                <span className="float-right">
                  <button
                    onClick={this.handleHide}
                    className="btn btn-default brder-2 btn-sm mr-2"
                  >
                    {i18n.t("Cancel")}
                  </button>
                  <button
                    onClick={this.handleSubmit}
                    className="btn btn-primary btn-sm mr-2"
                  >
                    {this.state.mode === "edit" ? (
                      <>{i18n.t("Update")}</>
                    ) : (
                      <>{i18n.t("Add")}</>
                    )}
                  </button>
                </span>
              </div>
            </Modal.Footer>
          }
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    pulseWholeData: [...state.CurrentFilter.PulseData.data],
    pulsePageData: [...state.CurrentFilter.pulsePageData.pulseData],
    languageData: state.CurrentFilter.languageData,
    clientData: state.CurrentFilter.ClientData,
    maxSequence: state.CurrentFilter.pulseMaxSequence,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddNewAsset: (data) => {
      dispatch({ type: "ADDNEW_ASSET", payload: data });
    },
    onSavePulseMaxSequences: (data) => {
      dispatch({ type: "PULSE_MAX_SEQUENCE", payload: data });
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewAsset);
